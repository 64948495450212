import * as React from "react";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { useAuthUser } from "./Session";

const REASONS = [
  "Please select one...",
  "Instagram",
  "Facebook",
  "Pinterest",
  "Internet search",
  "Email",
  "Other fitness professional",
  "Current member",
];

const initialState = {
  phone: "",
  instagram: "",
  signupReason: "",
  reasonMember: null,
  loading: false,
  submitted: false,
};

const phonePattern = /^[2-9]\d{2}-?\d{3}-?\d{4}$/;

export default function AdditionalInfoForm() {
  const [state, setState] = React.useState(initialState);
  const { uid } = useAuthUser();

  const handleOnChange = (event) => {
    const newState = {
      [event.target.name]: event.target.value,
    };

    setState({ ...state, ...newState });
  };

  const handleSubmit = React.useCallback(async () => {
    try {
      setState({ ...state, loading: true });

      const { phone, instagram, signupReason, reasonMember } = state;

      const submitResult = await fetch(
        "/.netlify/functions/update-user-fields",
        {
          method: "POST",
          body: JSON.stringify({
            uid,
            phone,
            instagram,
            signupReason,
            reasonMember,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const submitJson = await submitResult.json();
      if (submitJson.status === "success") {
        setState({
          ...state,
          loading: false,
          submitted: true,
        });
      } else {
        // do error case
        console.log("failed API call");
      }
    } catch (err) {
      // do error case
      console.error(err);
    }
    // eslint-disable-next-line
  }, [state]);

  const reasonInvalid =
    state.signupReason === "" ||
    state.signupReason === "none" ||
    (state.signupReason === "Current member" && !state.reasonMember);

  const phoneInvalid = phonePattern.test(state.phone) === false;

  const submitInvalid = phoneInvalid || reasonInvalid;

  if (state.submitted) {
    return (
      <div className="account-info-container">
        <div style={{ textAlign: "center" }}>
          Thanks for the update! Now get to it!!
        </div>
      </div>
    );
  }

  return (
    <div className="account-info-container">
      <div>🖤 WAIT! 🖤</div>
      <div>
        Before you hit the ground running, take a minute to give me a few more
        pieces of information so I can help you stay #BALANCED! Just a welcome
        shout out and a few texts per month (maximum 6) to help you stay on
        track!
      </div>
      <div className="account-info-form">
        <div className="col">
          <label htmlFor="username">Phone *</label>
          <input
            name="phone"
            value={state.phone}
            onChange={handleOnChange}
            placeholder="555-123-4567"
            type="text"
          />
          {state.phone && phoneInvalid && (
            <p className="field-error">Phone is invalid.</p>
          )}
        </div>

        <div className="col">
          <label htmlFor="username">Instagram</label>
          <input
            name="instagram"
            value={state.instagram}
            onChange={handleOnChange}
            placeholder="@janesmith"
            type="text"
          />
        </div>

        <div className="col">
          <label htmlFor="username">How did you hear about us? *</label>
          <select name="signupReason" onChange={handleOnChange}>
            {REASONS.map((x, index) => (
              <option
                key={`heard-reason-option-${index}`}
                value={index === 0 ? "none" : x}
              >
                {x}
              </option>
            ))}
          </select>
          {state.signupReason === "Current member" && (
            <input
              className="reason-member"
              name="reasonMember"
              type="text"
              onChange={handleOnChange}
              placeholder="Who? Let us thank them!"
            />
          )}
        </div>

        <div className="col button-container">
          <button
            className="fancy black"
            disabled={submitInvalid || state.loading}
            onClick={handleSubmit}
          >
            {state.loading ? (
              <>
                <FaSpinner
                  className="fa-spin"
                  style={{ color: "#fff", marginRight: 10 }}
                />
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
