import {
  withActiveSubscription,
  withAuthorization,
} from "../../components/Session";
import SuccessImage from "../../../static/img/lets_do_this.jpg";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
import React from "react";
import { compose } from "recompose";
import PageHeader from "../../components/PageHeader";
import Seo from "../../components/seo";
import { BookNowButton } from "../../components/FoldBar";
import AdditionalInfoForm from "../../components/AdditionalInfoForm";

const GettingStartedPageBase = () => {
  return (
    <div className="getting-started">
      <Seo
        title="Welcome to the Balance Family!"
        description="A welcome packet of information on getting started with your balance journey!"
      />
      <PageHeader title={"Welcome to the #Balance Family!"} />
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h2 className="has-text-centered">Let's get you started!</h2>
              <div
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="promo-success-image"
                  src={SuccessImage}
                  alt={"Excited Kristie"}
                  style={{ borderRadius: 20 }}
                />
              </div>
              <AdditionalInfoForm />
              <div style={{ height: "30px" }} />
              <strong>Step #1</strong>
              <p>
                Book your FREE "Balance Consultation" with me upon joining!
                We'll virtually chat 1:1 and create a game plan for you to get
                started with the program! Just click the link below and choose
                any available appointment time that works for you! I will do
                everything I can to help motivate you to find your #balance! I
                can't wait to meet you!
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BookNowButton
                  showIcon={false}
                  title="Book your free Zoom consultation NOW!"
                  href="https://calendly.com/kristielengel/balance-consultation"
                />
              </div>
              <br />
              <br />
              <strong>Step #2</strong>
              <p>
                If you need some help getting started with #BALANCED eating,
                make sure to get my 3-Week Kick Start Plan! Grocery lists, meal
                plans and recipes! Everything you need to get started!
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BookNowButton
                  showIcon={false}
                  title="Purchase the 3-Week Kick Start Plan"
                  href="https://buy.stripe.com/7sIbMO2xQ8VT56E288"
                />
              </div>
              <br />
              <br />
              <strong>Step #3</strong>
              <p>
                Join our "Members Only" Facebook #BALANCE group! You'll find
                motivating ladies from all over the country working to find
                their #BALANCE just like you! Team work makes the dream work
                right?!
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BookNowButton
                  showIcon={false}
                  title="Join the Members Only Facebook Group"
                  href="https://www.facebook.com/groups/kristielengel/?ref=share"
                />
              </div>
              <br />
              <br />
              <strong>Step #4</strong>
              <p>
                Get your starting measurements! We will use circumference
                measurements to best track your progress because the scale can
                be extremely deceiving. Either hide your scale or throw it out
                because we won't be needing it! Watch this instructional video
                on how to do your own measurements at home. I highly advise that
                you do these every 4-6 weeks!
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BookNowButton
                  showIcon={false}
                  title="Let's Do My Measurements!"
                  href="/training-videos/how-to-accurately-measure-your-progress/"
                />
              </div>

              <br />
              <br />
              <strong>Step #5</strong>
              <p>Check out these frequently asked questions!</p>
              <ol style={{ listStyleType: "decimal" }}>
                <li>What do I need to start?</li>
                <ul>
                  <li>A set of dumbbells</li>
                  <li>A mat or carpeted room</li>
                  <li>
                    A free timer app (this{" "}
                    <Link
                      className="light-link"
                      to="https://apps.apple.com/us/app/interval-timer/id1124297113"
                    >
                      one
                    </Link>{" "}
                    works great!)
                  </li>
                  <li>Your favorite music to get you going!</li>
                </ul>
                <li>When are the workouts posted?</li>
                <p>Every Sunday at 5:00pm!</p>
                <li>What does "Beef and Cheese" mean?</li>
                <p>
                  The "Beef and Cheese" is the big finish! A tough ending to
                  everything you did in the workout! Show me what ya got!
                </p>
              </ol>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <BookNowButton
                  showIcon={false}
                  title="I'm ready for my first workout!"
                  href="/?section=weekly-workout"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const condition = (authUser) => !!authUser;

const GettingStartedPage = compose(
  withAuthorization(condition),
  withActiveSubscription
)(GettingStartedPageBase);

const GettingStartedFinal = () => (
  <Layout>
    <GettingStartedPage />
  </Layout>
);

export default GettingStartedFinal;
